import React from 'react'
import { View } from 'react-native'

import { SmallMediumCardProps } from './interface'

export const SmallMediumCardBase = ({
    topIcon,
    topIconPosition,
    image,
    children,
}: SmallMediumCardProps) => {
    return (
        <>
            {topIcon && (
                <View
                    style={{
                        position: "absolute",
                        zIndex: 100,
                        elevation: 100,
                        left: 0,
                        top: 0,
                        alignItems:
                            topIconPosition === 'left'
                                ? 'flex-start'
                                : 'flex-end',
                    }}
                >
                    {topIcon}
                </View>
            )}
            {image && image}
            {children}
        </>
    )
}
